<template>
  <div>
    <div>
      <h4 class="dashboard__heading">Applications</h4>
    </div>
    <b-card
      class="card-app-design assigned-staff-card"
    >
    <b-table
      id="StudentsListTableId"
      ref="refStudentsListTable"
      class="position-relative agent-application-table"
      :items="data"
      responsive
    >
    </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style>
.agent-application-table th, .agent-application-table td{
  white-space: nowrap;
}
</style>
